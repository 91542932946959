@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.container-solutions {
    width: 1440px;
    height: 582px;
    top: 1291px;

}


.container-copy-list {
    margin-top: -50px;
}

.sol-title {
    position: relative;
    margin-left: 45px;
    width: 450px;
    height: 88px;
    top: 10px;
    left: 80px;
    font-family: 'Roboto', sans-serif;
    font-size: 40px;
    font-weight: 600;
    line-height: 44px;
    text-align: left;


}


.container-copy {
    margin-top: 120px;
    margin-left: 50px;
}

.container-list {
    margin-top: 18px;
}


.img-copy1 {
    margin-left: 80px;
}


.title-copy1 {
    width: 515px;
    height: 36px;
    margin-left: 80px;
    margin-top: 10px;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 36px;
    text-align: left;


}


.subtitle-copy1 {
    width: 400px;
    height: 90px;
    margin-left: 80px;
    margin-top: -10px;

}


.copy2 {
    margin-top: 60px;
}


.img-copy2 {
    margin-left: 80px;
}


.title-copy2 {
    width: 515px;
    height: 36px;
    margin-left: 80px;
    margin-top: 10px;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 36px;
    text-align: left;
}


.subtitle-copy2 {
    width: 400px;
    height: 90px;
    margin-left: 80px;
    margin-top: -10px;
}


.img-list1 {
    position: relative;
    margin-left: 1200px;
    top: -435px;
    align-items:center;
    
}


.title-list1 {
    position: relative;
    width: 515px;
    height: 36px;
    top: -435px;
    margin-left: 1200px;
    margin-top: 10px;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 36px;
    text-align: left;
}


.subtitle-list1 {
    position: relative;
    top: -435px;
    width: 400px;
    height: 90px;
    margin-left: 1200px;
    margin-top: -10px;
}


.img-list2 {
    position: relative;
    margin-left: 1200px;
    top: -390px;
    align-items:center;
}


.title-list2 {
    position: relative;
    width: 515px;
    height: 36px;
    top: -390px;
    margin-left: 1200px;
    margin-top: 10px;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 36px;
    text-align: left;   
}


.subtitle-list2 {
    position: relative;
    top: -390px;
    width: 400px;
    height: 90px;
    margin-left: 1200px;
    margin-top: -10px;
}


@media (min-width: 344px) and (max-width: 1080px) {
    .sol-title {
        width: 100%;
        font-size: 110%;
        left: -3%;
        
    }
}


@media (min-width: 344px) and (max-width: 1080px) {
    .copy1 {
        position: relative;
        left: -9.7%;
        bottom: 65px;
    }

    .img-copy1 {
        position: relative;
        width: 2%;
        left: -0.2%;
    }

    .title-copy1 {
        position: relative;
        font-size: 100%;
        font-weight: 500;
        bottom: 16px;
    }

    .subtitle-copy1 {
        position: relative;
        width: 28%;
        font-family: 'Roboto', sans-serif;
        font-size: 88%;
        bottom: 24px;
    }
}

@media (min-width: 344px) and (max-width: 1080px) {
    .copy2 {
        position: relative;
        left: -9.7%;
        bottom: 160px;

    }

    .img-copy2 {
        position: relative;
        width: 1.7%;
        left: -0.2%;
    }

    .title-copy2 {
        position: relative;
        font-size: 100%;
        font-weight: 500;
        bottom: 16px;
    }

    .subtitle-copy2 {
        position: relative;
        width: 28%;
        font-family: 'Roboto', sans-serif;
        font-size: 88%;
        bottom: 24px;
    }
}


@media (min-width: 344px) and (max-width: 1080px) {
    .list1 {
        position: relative;
        left: -83.5%;
        bottom: -210px;
    }

    .img-list1 {
        position: relative;
        width: 1.5%;
        left: -0.2%;
        top: -418px;
    }

    .title-list1 {
        position: relative;
        left: -2px;
        bottom: 2px;
        font-size: 100%;
        font-weight: 500;
        
    }

    .subtitle-list1 {
        position: relative;
        width: 26%;
        left: -0.2%;
        font-family: 'Roboto', sans-serif;
        font-size: 88%;
        top: -443px;
    }
}


@media (min-width: 344px) and (max-width: 1080px) {
    .list2 {
        position: relative;
        left: -83.5%;
        bottom: -160px;
    }

    .img-list2 {
        position: relative;
        width: 1.8%;
        left: -0.3%;
        top: -418px;
    }

    .title-list2 {
        position: relative;
        left: -2px;
        top: -435px;
        font-size: 100%;
        font-weight: 500;
    }

    .subtitle-list2 {
        position: relative;
        width: 26%;
        left: -0.2%;
        font-family: 'Roboto', sans-serif;
        font-size: 88%;
        top: -443px;
    }
}

@media (min-width: 344px) and (max-width: 1080px) {
    .container-copy-list {
        position: relative;
        top: -10px;
    }
}