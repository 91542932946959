@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anek+Latin:wght@100..800&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

.main-dif {
    background-color: white;
    height: 20vh;
}

.cards-row-dif {
    height: 75vh;
}

.card1-dife {
    background-color: #C9A66A;
    color: white;
    font-family: 'Inter', sans-serif;
    width: 100%;
    height:260px;
    border: none;
}

.card2-dife {
    background-color: #C9A66A;
    color: white;
    font-family: 'Inter', sans-serif;
    width: 100%;
    height:260px;
    border: none;
}

.card3-dife {
    background-color: #C9A66A;
    color: white;
    font-family: 'Inter', sans-serif;
    width: 100%;
    height:260px;
    border: none;
}

.card4-dife {
    background-color: #C9A66A;
    color: white;
    font-family: 'Inter', sans-serif;
    width: 100%;
    height:260px;
    margin-top: -150px;
    border: none;
}

.card5-dife{
    background-color: #C9A66A;
    color: white;
    font-family: 'Inter', sans-serif;
    width: 48.5%;
    height:260px;
    margin-top: -150px;
    border: none;
    
}

.family {
    width:9%;
}

.group {
    width: 9%;
}


.title3-card {
    font-size: 1.24rem;
}


.btn-card1,
.btn-card2,
.btn-card4,.btn-card5 {
    color: #C9A66A;
    background-color: white;
    font-family: 'Inter', sans-serif;
    height: 5vh;
    align-items: center;
    border: none;
}

.btn-card3 {
    color: #C9A66A;
    background-color: white;
    margin-top: 0.9px;
    font-family: 'Inter', sans-serif;
    height: 5vh;
    align-items: center;
    border: none;
}


.locker {
    width: 6%;
}

.title-dif {
    font-family: 'Inter', sans-serif;
}

.subtitle-brasil {
    color: #828282;
    font-weight: 300;  
}



@media (min-width: 365px) {
    
    .card1-dife,
    .card2-dife,
    .card3-dife,
    .card4-dife,
    .card5-dife {
        min-height: 28vh;
        max-height: 32vh;
    }

    .cards-row-dif {
        height: 175vh;
    }

    .card2-dife {
        position: relative;
        top: -80px;
    }

    .card3-dife {
        position: relative;
        top: -160px;
    }

    .card4-dife {
        position: relative;
        top: -90px;
    }

    .card5-dife {
        position: relative;
        top: -20px;
    }

    
 
 }
 
 @media (min-width: 576px) {
    
    .cards-row-dif {
        height: 85vh;
    }

    .card1-dife {
        position: relative;
        height: 35vh;
    }

    .card2-dife {
        position: relative;
        top: -0.2px;
        height: 35vh;
    }

    .card3-dife {
        position: relative;
        top: -2px;
        height: 31vh;
    }

    .card4-dife {
        position: relative;
        top: 140px;
        height: 38vh;
    }

    .card5-dife {
        position: relative;
        top: 145px;
        height: 35vh;
    }

    .btn-card4 {
        position: relative;
        top: -4px
    }

    .line3 {
        position: relative;
        top: -3px;
    }

    .line4{
        position: relative;
        top: -3px;
    }

    .locker {
        width: 20px;
    }

    .family {
        width: 30px;
    }

    .group {
        width: 30px;
    }

    
 }
 
 
 
 
 @media (min-width: 768px) {
    .card1-dife,
    .card2-dife,
    .card3-dife,
    .card4-dife,
    .card5-dife {
        min-height: 25vh;
        max-height: 27vh;
    }

    .card4-dife {
        position: relative;
        top: 80px;
    }

    .card5-dife {
        position: relative;
        top: 10px;
    }

    .card3-dife {
        position: relative;
        top: -70px;
    }

    .cards-row-dif {
        height: 110vh;
    }

    .btn-card3 {
        position: relative;
        top: 4px;
        height: 5vh;
    }
 }
 
 @media (min-width: 992px) {
    .card1-dife,
    .card2-dife,
    .card3-dife,
    .card4-dife,
    .card5-dife {
        height: 28vh;
    }

    .card4-dife {
        position: relative;
        top: 80px;
    }

    .card5-dife {
        position: relative;
        top: 10px;
    }

    .card3-dife {
        position: relative;
        top: -70px;
    }

    .cards-row-dif {
        height: 100vh;
    }

    .btn-card1 {
        position: relative;
        top: 18px;
    }

    .btn-card2 {
        position: relative;
        top: 18px;
    }

    .btn-card3 {
        position: relative;
        top: -2px;
        height: 5vh;
    }

    .btn-card4 {
        position: relative;
        top: 18px;
    }

    .btn-card5 {
        position: relative;
        top: -7px;
        height: 5vh;
    }

    .line5 {
        position: relative;
        top: -7px;
    }

    .line4 {
        position: relative;
        top: 18px;
    }

    .line3 {
        position: relative;
        top: -5px;
    }

    .line2 {
        position: relative;
        top: 18px;
    }

    .line1 {
        position: relative;
        top: 18px;
    }
 }
 
 
 @media (min-width: 1024px) {
    
    .card1-dife,
    .card2-dife,
    .card3-dife,
    .card4-dife,
    .card5-dife {
        height: 28.5vh;
    }

    .cards-row-dif {
        height: 100vh;
    }

    .btn-card1 {
        position: relative;
        top: 19px;
    }

    .btn-card2 {
        position: relative;
        top: 19px;
    }

    .btn-card3 {
        position: relative;
        top: 1px;
    }

    .btn-card4 {
        position: relative;
        top: 20px;
    }

    .btn-card5 {
        position: relative;
        top: -5px;
    }

    .line1 {
        position: relative;
        top: 15px;
    }

    .line2 {
        position: relative;
        top: 15px;
    }

    .line3 {
        position: relative;
        top: -6px;
    }

    .line4 {
        position: relative;
        top: 15px;
    }

    .line5 {
        position: relative;
        top: -6px;
    }
 }
 
 @media (min-width: 1199px) {
     
    .card1-dife,
    .card2-dife,
    .card3-dife,
    .card4-dife,
    .card5-dife {
        min-height: 28vh;
        max-height: 30vh;
    }

    .card3-dife {
        position: relative;
        top: -0.2px;
    }

    .locker {
        width: 24px;
    }

    .btn-card3 {
        position: relative;
        top: 21px;
    }

    .line3 {
        position: relative;
        top: 13px;
    }

    .card4-dife {
        position: relative;
        top: -80px;
    }

    .card5-dife {
        position: relative;
        top: -80px;
    }

    .btn-card5 {
        position: relative;
        top: 19px;
    }

    .line5{
        position: relative;
        top: 12px;
    }

    .cards-row-dif {
        height: 100vh;
    }

  }
 
 
 @media (min-width: 1400px) {

    .card1-dife,
    .card2-dife,
    .card3-dife,
    .card4-dife,
    .card5-dife {
        min-height: 16vh;
        max-height: 28vh;
    }

    .group {
        width: 26px;
    }

    .family {
        width: 27px;
    }

    .locker {
        width: 24px;
    }

    .card3-dife {
        position: relative;
        top: -1px;
    }

    .card4-dife {
        position: relative;
        top: 10.4px;
    }

    .card5-dife {
        position: relative;
        top: 10px;
    }

    .btn-card1 {
        position: relative;
        top: 3px;
    }

    .btn-card2 {
        position: relative;
        top: 3px;
    }

    .btn-card3 {
        position: relative;
        top: 2.8px;
    }

    .btn-card4 {
        position: relative;
        top: 6px;
    }

    .btn-card5 {
        position: relative;
        top: 6px;
    }

    .line1 {
        position: relative;
        top: 2px;
    }

    .line2 {
        position: relative;
        top: 2px;
    }

    .line3 {
        position: relative;
        top: -1.5px;
    }

    .line4 {
        position: relative;
        top: 5px;
    }

    .line5 {
        position: relative;
        top: 5px;
    }

    .cards-row-dif {
        height: 80vh;
    }

 }
