@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anek+Latin:wght@100..800&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');



@media (min-width: 315px) {
    
  .row-header{
    height: 80vh;
    background-image: url('../../assets/img/Thiago-Principal/Thiago-Principal.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
  }

  .subtitle {
    text-align: center;
  }
    

}

@media (min-width: 576px) {
  .row-header{
    height: 80vh;
    background-image: url('../../assets/img/Thiago-Principal/Thiago-Principal.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
  }

    
 
}

@media (min-width: 768px){
  .row-header{
    height: 80vh;
    background-image: url('../../assets/img/Thiago-Principal/Thiago-Principal.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
  }

  .subtitle {
    text-align: start;
  }
    
}

@media (min-width: 992px){
  .row-header{
    height: 80vh;
    background-image: url('../../assets/img/Thiago-Principal/Thiago-Principal.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
  }

  
}

@media (min-width: 1200px){
  .row-header{
    height: 80vh;
    background-image: url('../../assets/img/Thiago-Principal/Thiago-Principal.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
  }

  
}

@media (min-width: 1400px){

    .row-header{
      height: 100vh;
      background-image: url('../../assets/img/Thiago-Principal/Thiago-Principal.png');
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      position: relative;
    }

    
    
  
}
















































