@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anek+Latin:wght@100..800&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');


@media (min-width: 320px) {
    .forms {
        height: 200vh;
    }

    .col-forms {
        order: 2;
    }
}

@media (min-width: 640px) {
    .forms {
        height: 150vh;
    }

    .col-forms {
        order: 1;
    }
}


@media (min-width: 768px) {
    .forms {
        height: 140vh;
    }

    .col-forms {
        order: 1;
    }
}

@media (min-width: 1024px) {
    .forms {
        height: 130vh;
    }

    .col-forms {
        order: 1;
    }
}