@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');


.main-thiago {
    height:50vh ;
}


.title-thiago {
    font-family: 'Work Sans', sans-serif;
    color: white;
}

@media (max-width: 576px) {
    .main-thiago {
        height: 70vh;
    }
}